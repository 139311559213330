/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var flightpath = {
  entry: {
    curviness: 1.25,
    // autoRotate: true,
    values: [
      { x: 50, y: -700 },
      { x: -20, y: -700 },
      { x: -80, y: -680 },
      { x: -280, y: -680 },
      { x: -680, y: -680 },
      { x: -880, y: -480 },
      { x: -1 * ($(window).width() + 300), y: 10 }
    ]
  }
};

function hideExperienceDetails(all) {
  var detail = $(".experience-detail.active");
  detail.removeClass("active");
  if (all) {
    detail = $(".experience-detail");
    detail.hide();
    return;
  }
  detail.slideUp(400);
}
function showExperienceDetailHandler(e, id, index, minCol = 2, maxCol = 6) {
  e.preventDefault();
  showExperienceDetail(id, index, minCol, maxCol);
}

function showExperienceDetail(id, index, minCol = 2, maxCol = 6) {
  var colcount;
  if (window.innerWidth < 576) {
    colcount = minCol;
  } else if (window.innerWidth < 768 && window.innerWidth >= 576) {
    colcount = 3;
  } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
    colcount = 4;
  } else {
    colcount = maxCol;
  }
  var toplace = Math.ceil(index / colcount) * colcount;
  var detail = $(".experience-detail[data-id=" + id + "]");
  var scrollto = $(".experience-item[data-index=" + index + "]");
  var target = $(".experience-item[data-index=" + toplace + "]");
  if (detail.hasClass("active")) {
    return;
  }
  hideExperienceDetails();
  detail.addClass("active");
  detail.insertAfter(target);
  detail.show();
  function scroll() {
    $("html, body").animate(
      {
        scrollTop: scrollto.offset().top - 40
      },
      400
    );
  }
  setTimeout(scroll, 400);
  $(".experience-detail[data-id=" + id + "] .slick-station").slick(
    "setPosition"
  );
  detail.hide();
  detail.slideDown(400);
}

// getting style values from dom elements
function getFinalStyle(domNode, properties) {
  if (!(properties instanceof Array)) properties = [properties];

  var parent = domNode.parentNode;
  if (parent) {
    var originalDisplay = parent.style.display;
    parent.style.display = "none";
  }
  var computedStyles = getComputedStyle(domNode);

  var result = {};
  properties.forEach(function(prop) {
    result[prop] = computedStyles[prop];
  });

  if (parent) {
    parent.style.display = originalDisplay;
  }

  return result;
}

(function($) {
  $(window).resize(function() {
    hideExperienceDetails();
  });
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        // JavaScript to be fired on all pages
        function onSubmit(token) {
          document.getElementById("d-form").submit();
        }
      },
      finalize: function() {
        // Mobile Menu animations
        $(".nav-icon").click(function() {
          $(this).toggleClass("open");
          $(this).closest('header').removeClass('scroll');
        });
        $(".collapse").collapse("hide");
        $(".menu-item-has-children").click(function(){
          $(this).closest('header').addClass('scroll');
          $(this).toggleClass('active');
          $(this).find('.nav-drop').toggle();
        });
        if (window.location.hash == "#hexenpost") {
          $('#NEWSLETTER').modal('show');
     }
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      
        window.scrollTo(0, 0);
      },
      finalize: function() { 
        var path = 'bla';
        particlesJS.load('particle-js', path, function() {
        });

        $(window).scroll(function() {
          if ($(document).scrollTop() > 5000) {
            $('.layer0').addClass("removeImg");
          } else {
            $('.layer0').removeClass("removeImg");
          }
        });
        // JavaScript to be fired on the home page, after the init JS
        window.scrollTo(0, 0);
        $.getDocHeight = function() {
          return Math.max(
            $(document).height(),
            $(window).height(),
            $("html").height(),
            $("body").height(),
            /* For opera: */
            document.documentElement.clientHeight
          );
        };
        // let docHeight = $(document).height();
        let docHeight = $.getDocHeight();
        let scrollDistance = docHeight - $(window).height();
        // let scrollDistance = docHeight - $("body").height();
        let windowWidth = $(window).width();
        let introHeight = docHeight / 2 - 100;
        // let ratio = windowHeight / windowWidth;
        let ratio = 5;

        // $("body").innerHeight(2550 * aspect + 800 * (aspect - 1));
        // $("body").innerHeight(2550 * aspect - 1000 * Math.abs(aspect - 1));
        // $("body").innerHeight(docHeight * 4);

        // mastering for parallax!

        $("body").css("overflow", "hidden");
        function setHeight() {
          const windowHeight = $(window).height();
          const aspect = windowWidth / windowHeight;
          if (aspect < 0.5) {
            ratio = 2;
            scrollDistance = scrollDistance * 4;
            $("body").outerHeight(
              150 * aspect + windowHeight * 3.4 + 200 * Math.abs(aspect - 1)
            );
          } else if (aspect < 0.8) {
            ratio = 1.2;
            scrollDistance = scrollDistance * 2;
            $("body").outerHeight(
              150 * aspect + windowHeight * 4.1 + 150 * Math.abs(aspect - 1)
            );
          } else if (aspect < 1) {
            $("body").outerHeight(
              150 * aspect + windowHeight * 2 + 500 * Math.abs(aspect - 1)
            );
          } else if (aspect > 2) {
            $("body").outerHeight(
              150 * aspect + windowHeight * 10 + 130 * (aspect - 1)
            );
          } else if (aspect >= 1.5) {
            $("body").outerHeight(
              150 * aspect + windowHeight * 4 + 4000 * (aspect - 1)
            );
          } else {
            $("body").outerHeight(
              150 * aspect + windowHeight * 3 + 3750 * (aspect - 1)
            );
          }
        }

        $(document).ready(function() {
          if ($("#intro").length) {
            setHeight();
          }
        });

        $(window).resize(function() {
          // docHeight = $(document).height();
          // scrollDistance = docHeight - $(window).height();
          // windowHeight = $(window).height();
          // windowWidth = $(window).width();
          // introHeight = docHeight / 2 - 100;
          // ratio = 1;
          // setHeight();
        });

        let currPos;
        let progress;
        function updatePos() {
          // const introHeight = $("#intro").innerHeight();
          scrollDistance = docHeight - $(window).height();
          currPos = $(window).scrollTop();
          progress = (100 * currPos) / scrollDistance;
          // $("#test").text(currPos);

          // console.log("currPos", currPos);
          // console.log("scrollDistance", scrollDistance);
          // console.log("progress", progress);
          // console.log("ratio", ratio);
          // if (currPos < introHeight - (windowHeight - 100)) {
          $(".layer0.layer").css(
            "transform",
            "translate3d(0," + (progress * -5) / (100 / ratio) + "%, 0)"
          );
          // $(".layer0.layer").css("transform", "translate3d(0, 20%, 0)");
          $(".layer1.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -28) / (100 / ratio) + "%, 0)"
          );
          $(".layer2.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -35) / (100 / ratio) + "%, 0)"
          );
          $(".layer3.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -40) / (100 / ratio) + "%, 0)"
          );
          $(".layer4.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -52) / (100 / ratio) + "%, 0)"
          );
          $(".layer5.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -57) / (100 / ratio) + "%, 0)"
          );

          $(".layer6.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -60) / (100 / ratio) + "%, 0)"
          );
          $(".layer7.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -65) / (100 / ratio) + "%, 0)"
          );
          $(".layer8.layer .sticky").css(
            "transform",
            "translate3d(0," + (progress * -70) / (100 / ratio) + "%, 0)"
          );
          // }
          // requestAnimationFrame(updatePos);
        }

        var firstScroll = true;
        function scrollHandler() {
          if ($("#intro").length) {
            updatePos();
          }
          if (firstScroll) {
            const a = ($(window).width() * 18) / 100;
            const scale = a / 300;
            $("#bee").css("transform", `scale(${scale})`);
            TweenMax.to($("#bee"), 5, {
              css: { bezier: flightpath.entry },
              ease: Power1.easeInOut
            });
            firstScroll = false;
          }
        }
        // $(document).on("scroll", scrollHandler);
        $(window).on("scroll", scrollHandler);

        // var throttleUpdatePos = _.throttle(updatePos, 10);
        // $(window).on("scroll", updatePos);
        if ($("#intro").length) {
          updatePos();
        }

        // start lazy load
        function loadImage(el, fn) {
          var img = new Image(),
            src = el.getAttribute("data-src");
          console.log(src);
          img.onload = function() {
            if (!!el.parent) el.parent.replaceChild(img, el);
            else el.src = src;

            fn ? fn() : null;
          };
          img.src = src;
        }

        var images = $("img.lazy");
        for (var i = 0; i < images.length; i++) {
          loadImage(images[i], function() {
            images.splice(i, i);
          });
        }
      }
    },
    // Fotos & Videos
    page_template_template_fotosundvideos: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
          $('.lazy').lazy({
            visibleOnly: true
          });
        // JavaScript to be fired on the home page, after the init JS
        var $grid = $(".grid").isotope({
          itemSelector: ".grid-item",
          percentPosition: true,
          filter: "*",
          masonry: {
            columnWidth: ".grid-sizer",
            gutter: ".gutter-sizer"
          }
        });

        $(".tags a").on("click", function() {
          var filter = $(this).data("tag");
          $grid.isotope({ filter: filter });
        });

        $(".grid .grid-item").on("click", function() {
          $(".grid .grid-item").removeClass("grid-item--width2");
          $(this).addClass("grid-item--width2");
          $grid.isotope("layout");
        });
      }
    },
    page_template_template_infos: {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".experience-detail").hide(); // Muss noch gemacht werden
        // JavaScript to be fired on the home page, after the init JS
        var $grid = $(".grid").isotope({
          itemSelector: ".grid-item",
          percentPosition: true,
          filter: "*",
          masonry: {
            columnWidth: ".grid-sizer",
            gutter: ".gutter-sizer"
          }
        });

        $(".tags a").on("click", function() {
          var filter = $(this).data("tag");
          $grid.isotope({ filter: filter });
        });

        $(".grid .grid-item").on("click", function() {
          $(".grid .grid-item").removeClass("grid-item--width2");
          $(this).addClass("grid-item--width2");
          $grid.isotope("layout");
        });
      }
    },
    single_format_standard: {
      init: function() {},
      finalize: function() {
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".gallery").slick({
          lazyLoad: "ondemand"
        });
      }
    },
    page_template_template_hexenwasser_reisen: {
      init: function() {},
      finalize: function() {
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".gallery").slick({
          lazyLoad: "ondemand"
        });
      }
    },
    page_template_template_news: {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".slick-news").slick({
          lazyLoad: "ondemand",
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        });
      }
    },
    page_template_template_contact: {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // Javascript to enable link to tab
        var url = document.location.toString();
        if (url.match("#")) {
          $('.nav-tabs a[href="#' + url.split("#")[1] + '"]').tab("show");
        }

        // Change hash for page-reload
        $(".nav-tabs a").on("shown.bs.tab", function(e) {
          window.location.hash = e.target.hash;
        });
      }
    },
    page_template_template_kinderreisen: {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // Not working
        $('a.anmelden[href^="#"]').bind("click.smoothscroll", function(e) {
          e.preventDefault();
          var target = this.hash,
            $target = $(target);

          $("html, body")
            .stop()
            .animate(
              {
                scrollTop: $target.offset().top - 40
              },
              900,
              "swing",
              function() {
                window.location.hash = target;
              }
            );
        });
      }
    },
    page_template_template_angebote: {
      finalize: function() {
        $(".gallery").slick({
          lazyLoad: 'ondemand'
       });
      }
    },
    page_template_template_erlebnisse: {
      finalize: function() {
        $(".gallery").slick({
          lazyLoad: 'ondemand'
       });
       $(".slick-station").slick({
        lazyLoad: "ondemand",
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

      if (window.location.hash) {
        var hash = window.location.hash.substring(1);
        $('.hash-selctor').each(function(){
          if ($(this).hasClass(hash)) {
            $(this).collapse('show');
          }
        });
      }

      $('#accordion-').on('shown.bs.collapse', function(){
        $('.slick-station').slick('refresh');
       });
      }
    },
    page_template_template_hexenwasser: {
      init: function() {
     
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        $('.essen').click(function(){
          $(this).parent().on('shown.bs.collapse', function(){
            var item = $(this).find('.slick-station');
            item.slick('refresh');
          });
          /*
          $(this).parent().on('hidden.bs.collapse', function(){
            var item = $(this).find('.slick-station');
            item.slick('destroy');
          });
          */
        });
        $('.schlafen').click(function(){
          $(this).parent().on('shown.bs.collapse', function(){
            var item = $(this).find('.slick-station');
            item.slick('refresh');
          });
          /*
          $(this).parent().on('hidden.bs.collapse', function(){
            var item = $(this).find('.slick-station');
            item.slick('destroy');
          });
          */
        });
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".slick-station").slick({
          infinite: true,
          lazyLoad: "ondemand",
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        })
        $(".gallery").slick({
           lazyLoad: 'ondemand'
        });
        $('.lazy').Lazy({
            });


        var hash = window.location.hash;
        if (hash) {
          console.log(hash);
          console.log($(hash).data("id"));
          showExperienceDetail($(hash).data("id"), $(hash).data("index"));
        }
      }
    },
    page_template_template_hexenwasser_winter: {
      finalize: function() {
        $('.essen').click(function(){
          $(this).parent().on('shown.bs.collapse', function(){
            var item = $(this).find('.slick-station');
            item.slick('refresh');
          });
        });
        $('.schlafen').click(function(){
          $(this).parent().on('shown.bs.collapse', function(){
            var item = $(this).find('.slick-station');
            item.slick('refresh');
          });
        })
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".slick-station").slick({
          infinite: true,
          lazyLoad: "ondemand",
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        })
        $(".gallery").slick({
           lazyLoad: 'ondemand'
        });
        $('.lazy').Lazy({
            });
      }
    },
    single_main_station: {
      finalize: function() {
        $('#accordion-1').on('shown.bs.collapse', function(){
          $('.slick-station').slick('refresh');
        });
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".slick-station").slick({
          infinite: true
        })
      }
    },
    single_winter_station: {
      finalize: function() {
        $('#accordion-1').on('shown.bs.collapse', function(){
          $('.slick-station').slick('refresh');
        });
        $('.lazy').lazy({
          visibleOnly: true
        });
        $(".slick-station").slick({
          infinite: true
        })
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
